import React, { useState, useEffect, useRef } from 'react'
import cookies from 'next-cookies'
import { useRouter } from 'next/router'
import Head from 'next/head'

import dynamic from 'next/dynamic'
import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'

import { Spinner } from 'baseui/spinner'

function MagicLogin({ socialCallback, socialProvider }) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  // const { user, loading } = useAuth();

  const router = useRouter()

  const loginEmailRef = useRef()

  useEffect(() => {
    // check for oauth login
    if (socialCallback) {
      setLoading(true)
      // logged in redirect with google
      socialAuthCallback()
    }
  }, [])

  const socialAuthCallback = async () => {
    const result = await new Magic(process.env.NEXT_PUBLIC_MAGIC_PUB_KEY, {
      extensions: [new OAuthExtension()],
    }).oauth.getRedirectResult()

    // Once we have the did from magic, login with our own API
    const authRequest = await fetch('/api/login', {
      method: 'POST',
      headers: { Authorization: `Bearer ${result.magic.idToken}` },
    })

    if (authRequest.ok) {
      router.push('/dashboard')
    } else {
      /* handle errors */
    }
  }

  const loginWithMagic = async () => {
    if (validateEmail(email)) {
      setLoading(true)
      setEmailError(false)

      try {
        const did = await new Magic(
          process.env.NEXT_PUBLIC_MAGIC_PUB_KEY
        ).auth.loginWithMagicLink({ email: email })

        // Once we have the did from magic, login with our own API
        const authRequest = await fetch('/api/login', {
          method: 'POST',
          headers: { Authorization: `Bearer ${did}` },
        })

        if (authRequest.ok) {
          router.push('/dashboard')
        } else {
          /* handle errors */
        }
      } catch {
        setLoading(false)
        setEmailError(false)
        loginEmailRef.current.focus()
        loginEmailRef.current.select()
      }
    } else {
      setEmailError(true)
    }
  }

  const loginWithMagicTestMode = async () => {
    setLoading(true)
    setEmailError(false)

    try {
      const did = await new Magic(process.env.NEXT_PUBLIC_MAGIC_PUB_KEY, {
        testMode: true,
      }).auth.loginWithMagicLink({ email: 'test+success@magic.link' })

      const authRequest = await fetch('/api/login-testmode', {
        method: 'POST',
        headers: { Authorization: `Bearer ${did}` },
      })

      if (authRequest.ok) {
        router.push('/dashboard')
      }
    } catch (err) {
      setLoading(false)
      setEmailError(false)
      loginEmailRef.current.focus()
      loginEmailRef.current.select()
    }
  }

  const loginWithMagicSocial = async () => {
    let callback_url = 'http://localhost:8181/login'

    const prod = process.env.NODE_ENV === 'production'

    if (prod) {
      callback_url = 'https://app.onepager.io/login'
    }

    const did = await new Magic(process.env.NEXT_PUBLIC_MAGIC_PUB_KEY, {
      extensions: [new OAuthExtension()],
    }).oauth.loginWithRedirect({
      provider: 'google',
      redirectURI: callback_url,
      scopes: ['user:email'],
    })
  }

  function loginOnEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      loginWithMagic()
    }
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  return (
    <div className="p-20 dottedBG h-screen flex flex-col items-center justify-center overflow-y-auto min-h-screen">
      <Head>
        <title>Onepager.io - Login</title>
      </Head>

      <div className="flex items-center mb-10 font-bold text-xl">
        <img
          className="h-16 w-auto"
          src="https://cdn.filestackcontent.com/jW9hY5L5RAWWpn2rGhw9"
          alt="onepager"
        />
      </div>

      <div className="bg-white rounded shadow-xl p-10 loginBox relative">
        {loading && (
          <div className="absolute top-0 left-0 h-full w-full bg-white  flex flex-col items-center justify-center">
            <Spinner color="#ffc900" />
            <h3 className="mt-5 text-2xl font-bold text-center">
              Logging In...
            </h3>
          </div>
        )}
        <h2 className="mb-3 text-2xl font-bold text-center">
          Login Into Onepager
        </h2>
        <p className="text-center mb-5 text-sm">
          Enter in your email to log in, or create a new account... You will
          recieve an email to login.
        </p>

        {emailError && (
          <div className="p-2 rounded bg-yellow-200  font-medium mb-5 text-center">
            Opps! Not a real email... :)
          </div>
        )}

        <input
          autoFocus
          value={email}
          ref={loginEmailRef}
          onChange={e => {
            let emailLower = e.target.value
            setEmail(emailLower.toLowerCase())
          }}
          onKeyDown={loginOnEnter}
          className="block border-2 text-lg ring ring-gray-100 border-black w-full p-4 py-4 font-medium rounded shadow-inner"
          type="email"
          placeholder="Email address..."
        />

        <button
          onClick={loginWithMagic}
          className="block w-full mb-2 mt-3 p-2 py-4 rounded bg-black text-white text-lg font-medium"
        >
          Login With Your Email
        </button>

        <div className="text-center font-medium">OR</div>

        <button
          onClick={loginWithMagicSocial}
          className="block w-full mb-2 p-2 py-4 rounded bg-black text-white mt-2 text-lg font-medium"
        >
          Login With Google
        </button>
      </div>
    </div>
  )
}

export default MagicLogin

export async function getServerSideProps(context) {
  let isSocialCB = false
  let socialProvider = false

  if (context.query.provider == 'google') {
    isSocialCB = true
    socialProvider = 'google'
  }

  return {
    props: {
      socialCallback: isSocialCB,
      socialProvider: socialProvider,
    },
  }
}
